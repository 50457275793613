
import { computed, defineComponent, ref, watch } from "vue";
import store from "@/store";
import { LoadEntity, LoadFilterObjects } from "@/core/composite/composite";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import QuickAction from "@/components/base/action/QuickAction.vue";
import { setCurrentPageBreadcrumbsWithParams } from "@/core/helpers/breadcrumb";
import Row from "@/components/base/common/Row.vue";
import Avatar from "@/components/base/common/Avatar.vue";
import KtDatatable from "@/components/kt-datatable/KTDatatable.vue";
import DateTimeFormat from "@/components/base/datetime/DateTimeFormat.vue";
import PageStatus from "@/views/page/PageStatus.vue";
import Panel from "@/components/base/panel/Panel.vue";
import ShortPageForm from "@/views/page/ShortPageForm.vue";
import PageService from "@/core/services/DocService";
import Swal from "sweetalert2";

export default defineComponent({
  name: "PageDetail",
  components: {ShortPageForm, Panel, PageStatus, DateTimeFormat, KtDatatable, Avatar, Row, QuickAction},
  props: {
    pageId: {required: true, type: String}
  },
  setup(props) {
    const state = ref({title: '', show: false, id: null})
    const model = ref({name: '', type: '', body: '', parentId: '', description: '', status: ''})
    const refId = ref<any>(props.pageId);
    const filterObject = ref({parentId: refId.value});
    const pageEntity = computed(() => store.state.DocModule.pageEntity)
    const listPage = computed(() => store.state.DocModule.pages)
    watch(() => store.state.DocModule.pageEntity, (cb) => {
      let pageTitle = 'Collection';
      if (cb.data.type === 'FOLDER') {
        pageTitle = 'Folder';
      }
      const data: any = []
      if (cb.data.collection) {
        data.push({link: true, router: '/doc/collection/' + cb.data.collection.id, text: cb.data.collection.name})
      }
      if (cb.data.folder) {
        data.push({link: true, router: '/doc/folder/' + cb.data.folder.id, text: cb.data.folder.name})
      }
      data.push({link: false, router: '', text: cb.data.name})
      setCurrentPageBreadcrumbsWithParams(pageTitle, data)
    })
    const tableHeader = ref([
      {
        name: "Name",
        key: "name",
        sortable: true,
      },
      {
        name: "Created By",
        key: "createdBy",
        sortable: true,
      },
      {
        name: "Status",
        key: "status",
        sortable: true,
      },
      {
        name: "Created Date",
        key: "date",
        sortable: true,
      },
      {
        name: "",
        key: "actions",
        sortable: true,
      },
    ]);
    return {
      ...LoadEntity(Actions.LOAD_PAGE),
      ...LoadFilterObjects(Actions.LOAD_PAGES, filterObject.value, ['createdBy'], 'sort:order:asc'),
      pageEntity,
      tableHeader,
      listPage,
      filterObject,
      refId,
      model,
      state,
    }
  },
  watch: {
    pageId(objectId) {
      this.filterObject.parentId = objectId;
      this.updateFilterObject(this.filterObject)
    }
  },
  methods: {
    onClosePanel() {
      this.state.show = false;
    },
    onAdd(type) {
      this.model.name = '';
      this.model.type = type;
      this.model.parentId = this.pageId
      this.model.body = '';
      this.model.status = 'DRAFT';
      this.model.description = ''
      if (type === 'PAGE') {
        this.state.title = 'Add Doc'
      } else {
        this.state.title = 'Add Folder'
      }
      this.state.show = true
      this.state.id = null
    },
    onEdit(page) {
      this.model.name = page.name;
      this.model.type = page.type;
      this.model.parentId = page.parentId
      this.model.body = page.body;
      this.model.status = page.staus;
      this.model.description = page.description
      if (page.type === 'COLLECTION') {
        this.state.title = 'Edit Collection'
      } else if (page.type == 'FOLDER') {
        this.state.title = 'Edit Folder'
      } else {
        this.state.title = 'Edit Doc'
      }
      this.state.show = true
      this.state.id = page.id
    },
    onSavePage(data) {
      store.commit(Mutations.SET_PAGE, data);
      this.reloadData(this.pageId)
      this.paginationLoad();
      this.state.show = false;
    },
    updateStatus(id, status) {
      PageService.updateStatus(id, status).then(() => {
        this.paginationLoad();
      })
    },

    onDeleteEntity(page) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        showLoaderOnConfirm: true,
        heightAuto: false,
        preConfirm: async () => {
          await PageService.delete(page.id).then(() => {
            store.commit(Mutations.DELETE_PAGE_ITEM, page)
            if (page.type === 'COLLECTION') {
              this.$router.push('/dashboard')
            } else {
              this.$router.push('/doc/collection/' + page.collection.id)
            }
          })
        }
      })
    },
    onDeleteItem(page) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        showLoaderOnConfirm: true,
        heightAuto: false,
        preConfirm: async () => {
          await PageService.delete(page.id).then(() => {
            store.commit(Mutations.DELETE_PAGE_ITEM, page)
            this.paginationLoad()
          })
        }
      })
    }
  }
})
