
import { defineComponent, ref } from "vue";
import PageDetail from "@/views/page/PageDetail.vue";
import store from "@/store";
import { Mutations } from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "Collection",
  components: {PageDetail},
  props: {
    id: {required: true, type: String}
  },
  setup(props) {
    const refId = ref(props.id);
    store.subscribe(mutation => {
      if (mutation.type === Mutations.SET_MENU) {
        store.commit(Mutations.SET_ACTIVE_MENU, refId.value)
      }
    });
  }
})
